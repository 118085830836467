import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';

import { sortByProperty } from '../../utils/sortValues';

import { APIError, APIStatus } from '../../@types/APIStatus';
import { ProductListFilterBarState } from '../../@types/filterBars';
import { Pagination, ProductListState } from '../../@types/reduxStates';
import { ProductListRow } from '../../@types/tableRows';
import { ProductListResponse } from '../../@types/responsesAPI';
import { SortBy } from '../../@types/filters';

const initialState: ProductListState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceChannels: [],
    mandantShops: [],
    searchName: '',
    stockStatus: [],
    customerList: [],
    isBundle: false,
    revenue: [0, 1000000],
    revenueMargin: [0, 1000000],
    returnsQty: [0, 1000],
    isActivity: false,
    isRecommendations: false,
    isCritical: false
  },
  response: {
    rows: [],
    total: {
      revenue: 0,
      orders: 0,
      margin: 0,
      stock: 0,
      avgPrice: 0,
      returns: 0,
      activityCritical: 0,
      activityNormal: 0,
      productHealth: 0
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE,
  uri: ''
};

const slice = createSlice({
  name: 'itemsProducts',
  initialState,
  reducers: {
    setItemsProducts: (
      state: any,
      action: PayloadAction<Array<ProductListRow>>
    ) => {
      state.items = action.payload;
    },
    setFilters: (
      state: any,
      action: PayloadAction<ProductListFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getItemsProducts: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getItemsProductsSuccess: (
      state: any,
      action: PayloadAction<ProductListResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getOneProductSuccess: (
      state: any,
      action: PayloadAction<ProductListRow>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      // state.items.push(action.payload);
    },
    getItemsProductsError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export async function fetchProductItemsData(
  filters: ProductListFilterBarState,
  pagination: Pagination,
  sort: SortBy,
  signal: AbortSignal,
  variant: string = '',
  isCSV = false
): Promise<any> {
  const route = isCSV ? '/api/v2/productList/csv' : '/api/v2/productList';

  const {
    sourceChannels,
    stockStatus,
    searchName,
    timePeriod,
    isBundle,
    isActivity,
    isCritical,
    isRecommendations,
    revenue,
    revenueMargin,
    mandantShops,
    customerList,
    returnsQty
  } = filters;

  const { skip, limit } = pagination;
  const { by, order } = sort;

  const response = await axios.get(route, {
    signal,
    params: {
      variant: variant.length > 0 ? variant : undefined,
      skip,
      limit,
      by,
      order,
      from: timePeriod.startFilter,
      till: timePeriod.endFilter,
      isBundle: isBundle ? 1 : undefined,
      isActivity: isActivity ? 1 : undefined,
      isCritical: isCritical ? 1 : undefined,
      isRecommendations: isRecommendations ? 1 : undefined,
      source:
        sourceChannels.length > 0
          ? sourceChannels.map((c) => c.id).join(',')
          : undefined,
      shop:
        mandantShops.length > 0
          ? mandantShops.map((c) => c.id).join(',')
          : undefined,
      customers:
        customerList.length > 0
          ? customerList.map((c) => c.id).join(',')
          : undefined,
      stock:
        stockStatus.length > 0
          ? stockStatus.map((c) => c.id).join(',')
          : undefined,
      searchName: searchName.length > 0 ? searchName : undefined,
      revenueFrom: revenue[0],
      revenueTo: revenue[1],
      marginFrom: revenueMargin[0],
      marginTo: revenueMargin[1],
      returnsFrom: returnsQty[0],
      returnsTo: returnsQty[1]
    }
  });

  return response.data;
}

export function fetchProductItems(
  filters: ProductListFilterBarState,
  pagination: Pagination,
  sort: SortBy,
  abortController: AbortController,
  variant: string = ''
) {
  return async (dispatch: any) => {
    dispatch(getItemsProducts());
    dispatch(setFilters(filters));
    try {
      const data = await fetchProductItemsData(
        filters,
        pagination,
        sort,
        abortController.signal,
        variant
      );

      dispatch(getItemsProductsSuccess(data));
    } catch (error) {
      dispatch(getItemsProductsError(error as APIError));
    }
  };
}

export function sortBy(
  list: Array<ProductListRow>,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  const orderedList = sortByProperty(list, orderBy, order);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setItemsProducts(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setItemsProducts,
  setFilters,
  getItemsProducts,
  getItemsProductsSuccess,
  getItemsProductsError,
  getOneProductSuccess
} = slice.actions;
