import { useEffect, useState } from 'react';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason
} from '@material-ui/core';
import AutocompleteMulti from '../../general/form/AutocompleteMulti';
import { MultiselectItem } from '../../../@types/filters';

type Props = {
  label: string;
  placeholder: string;
  valueSelected: Array<MultiselectItem>;
  options: Array<MultiselectItem>;
  onChange: (value: Array<MultiselectItem>) => void;
};

function MultiselectDefault({
  label,
  placeholder,
  options,
  valueSelected,
  onChange
}: Props) {
  const [items, setItems] = useState<Array<MultiselectItem>>(valueSelected);
  const [isOpen, setOpen] = useState<boolean>(false);
  useEffect(() => setItems(valueSelected), [valueSelected]);

  function handleChange(
    event: React.SyntheticEvent<Element, Event>,
    value: MultiselectItem[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<MultiselectItem> | undefined
  ) {
    switch (reason) {
      case 'clear': {
        setItems(value);
        onChange(value);
        break;
      }
      case 'select-option': {
        setItems(value);
        break;
      }
      case 'remove-option': {
        setItems(value);
        if (!isOpen) onChange(value);
        break;
      }
    }
  }

  return (
    <AutocompleteMulti
      onClose={(ev, reason) => {
        setOpen(false);
        onChange(items);
      }}
      onOpen={(ev) => setOpen(true)}
      label={label}
      placeholder={placeholder}
      valueSelected={items}
      options={options}
      onChange={(ev, value, reason) => handleChange(ev, value, reason)}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  );
}

export default MultiselectDefault;
