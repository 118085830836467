import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { DemandPlanningFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { DemandPlanningState, Pagination } from '../../@types/reduxStates';
import { DemandPlanningResponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: DemandPlanningState = {
  filters: {
    warehouseId: 0,
    sources: [],
    suppliers: [],
    customers: [],
    categories: [],
    searchNameDp: ''
  },
  response: {
    rows: [],
    total: {
      articleStock: 0,
      reorderCalc_es: 0,
      reorderCalc_ets: 0,
      productPeriodAvgDeliveryTime: 0,
      sumSales: 0,
      sumEts: 0,
      sumExponentialSmooth: 0,
      env: 'dev'
    },
    totalCount: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'demandPlanning',
  initialState,
  reducers: {
    setFilters: (
      state: DemandPlanningState,
      action: PayloadAction<DemandPlanningFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getDemandPlanning: (state: DemandPlanningState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getDemandPlanningSuccess: (
      state: DemandPlanningState,
      action: PayloadAction<DemandPlanningResponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getDemandPlanningError: (
      state: DemandPlanningState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function updateDemandPlanningFilterBarState(
  filterBar: DemandPlanningFilterBarState
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBar));
  };
}

export function fetchDemandPlanning(
  filterBarState: DemandPlanningFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBarState));
    dispatch(getDemandPlanning());
    try {
      const params = {
        skip,
        limit,
        by,
        order,
        searchName:
          filterBarState.searchNameDp && filterBarState.searchNameDp.length > 0
            ? filterBarState.searchNameDp
            : undefined,
        customers:
          filterBarState.customers.length > 0
            ? filterBarState.customers.map((c) => c.id).join(',')
            : undefined,
        sources:
          filterBarState.sources.length > 0
            ? filterBarState.sources.map((s) => s.id).join(',')
            : undefined,
        category:
          filterBarState.categories.length > 0
            ? filterBarState.categories.map((c) => c.categoryExtId).join(',')
            : undefined,
        suppliers:
          filterBarState.suppliers.length > 0
            ? filterBarState.suppliers.map((s) => s.id).join(',')
            : undefined,
        warehouseId:
          filterBarState.warehouseId > 0
            ? filterBarState.warehouseId
            : undefined
      };
      const response = await axios.get('/api/v2/demandPlanning', {
        params,
        signal: abortController.signal
      });
      dispatch(getDemandPlanningSuccess(response.data));
    } catch (error) {
      dispatch(getDemandPlanningError(error as APIError));
      dispatch(getDemandPlanningSuccess(initialState.response));
    }
  };
}

export const {
  setFilters,
  getDemandPlanning,
  getDemandPlanningError,
  getDemandPlanningSuccess
} = slice.actions;

export default slice.reducer;
