import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  GridSize
} from '@material-ui/core';
import { ReactNode, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import { FilterSelect } from '../items/filters/FilterSelect';
import SelectTimePeriod from './SelectTimePeriod';
import { AreaChartFiltersState } from '../../@types/filterBars';
import useFilters from '../../hooks/useFilters';
import {
  ShopSelectItem,
  SourceSelectItem,
  CustomerSelectItem,
  CountrySelectItem
} from '../../@types/filters';
import { APIStatus } from '../../@types/APIStatus';

type AreaChartFilterProps = {
  ready?: boolean;
  showCountry?: boolean;
  showSources?: boolean;
  showDateRange?: boolean;
  showProfit?: boolean;
  title: string;
  children: ReactNode;
  filters: AreaChartFiltersState;
  columnsByFilter?: GridSize;
  onFiltersChange: (filters: AreaChartFiltersState) => void;
};

export default function AreaChartFilter({
  ready = true,
  showCountry = true,
  showSources = true,
  showDateRange = true,
  showProfit = false,
  columnsByFilter = 3,
  title,
  children,
  filters,
  onFiltersChange
}: AreaChartFilterProps) {
  const { t: translate } = useTranslation(['component']);

  const {
    response,
    getSourceChannels,
    getCustomers,
    getMandantShops,
    getCountries,
    countryOrders_old,
    customers_old,
    shopList_old,
    sourcesList_old,
    filtersStatus: {
      sourceChannels: scStatus,
      mandantShops: msStatus,
      customers: cStatus,
      countries: oStatus
    }
  } = useFilters();

  useEffect(() => {
    if (scStatus === APIStatus.IDLE || scStatus === APIStatus.REJECTED) {
      getSourceChannels();
    }
    if (msStatus === APIStatus.IDLE || msStatus === APIStatus.REJECTED) {
      getMandantShops();
    }
    if (cStatus === APIStatus.IDLE || cStatus === APIStatus.REJECTED) {
      getCustomers();
    }
    if (oStatus === APIStatus.IDLE || oStatus === APIStatus.REJECTED) {
      getCountries();
    }
  }, []);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title={translate(title)} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12} justifyContent="flex-end">
            {shopList_old.length > 1 && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={
                    shopList_old.findIndex((s) => s.shopId === filters.shopId) +
                    1
                  }
                  title={translate('component:account')}
                  labels={[
                    translate('filter:all_shops'),
                    ...shopList_old.map((shop: ShopSelectItem) => shop.shopName)
                  ]}
                  onChangeSelected={(option) => {
                    onFiltersChange({
                      ...filters,
                      shopId:
                        option === 0 ? 0 : response.shops[option - 1].shopId
                    });
                  }}
                />
              </Grid>
            )}
            {sourcesList_old.length > 0 && showSources && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={
                    sourcesList_old.findIndex(
                      (s) => s.sourceId === filters.sourceId
                    ) + 1
                  }
                  title={translate('sources')}
                  labels={[
                    translate('filter:all_sources'),
                    ...sourcesList_old.map(
                      (s: SourceSelectItem) => s.sourceName
                    )
                  ]}
                  onChangeSelected={(option) => {
                    onFiltersChange({
                      ...filters,
                      sourceId:
                        option === 0 ? 0 : sourcesList_old[option - 1].sourceId
                    });
                  }}
                />
              </Grid>
            )}
            {customers_old && customers_old.length > 0 && showSources && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={
                    customers_old.findIndex(
                      (s) => s.customerId === filters.customerId
                    ) + 1
                  }
                  title={translate('component:customer_class')}
                  labels={[
                    translate('filter:all_customers'),
                    ...customers_old.map(
                      (s: CustomerSelectItem) => s.customerName
                    )
                  ]}
                  onChangeSelected={(option) => {
                    onFiltersChange({
                      ...filters,
                      customerId:
                        option === 0 ? 0 : customers_old[option - 1].customerId
                    });
                  }}
                />
              </Grid>
            )}
            {showProfit && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={filters.profit ?? 0}
                  title={translate('profit_by')}
                  labels={[translate('by_order'), translate('by_invoice')]}
                  onChangeSelected={() => {}}
                />
              </Grid>
            )}

            {showCountry && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <FilterSelect
                  disabled={!ready}
                  selected={
                    countryOrders_old.findIndex(
                      (c) => c.countryId === filters.countryId
                    ) + 1
                  }
                  title={translate('filter:country')}
                  labels={[
                    translate('filter:all_countries'),
                    ...countryOrders_old.map((c) => c.countryName)
                  ]}
                  onChangeSelected={(option) => {
                    onFiltersChange({
                      ...filters,
                      countryId:
                        option === 0
                          ? 0
                          : countryOrders_old[option - 1].countryId
                    });
                  }}
                />
              </Grid>
            )}
            {showDateRange && (
              <Grid item xs={12} sm={6} lg={columnsByFilter}>
                <SelectTimePeriod
                  disabled={!ready}
                  startFilter={filters.timePeriod.startFilter}
                  endFilter={filters.timePeriod.endFilter}
                  onChangePeriod={(dRange) => {
                    onFiltersChange({
                      ...filters,
                      timePeriod: dRange
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
