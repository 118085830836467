import {
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { ChangeEvent, useEffect } from 'react';
import { textStyles } from '../../../theme/TextClasses';
import StockSelectOption from '../../general/StockSelectOption';

const useStyles = makeStyles<Theme>((theme) => ({
  customTextField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.action.disabledBackground
      }
    }
  },
  noPadding: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.action.disabledBackground
      },
      '& .MuiSelect-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0px 0px 0px 14px',
        minHeight: '37.125px',
        boxSizing: 'border-box'
      }
    }
  }
}));

type FilterSelectProps = {
  selected: number;
  title: string;
  labels: Array<string | { label: string; color: string }>;
  disabled?: boolean;
  noPadding?: boolean;
  onChangeSelected: (option: number) => void;
};

export function FilterSelect({
  disabled = false,
  selected,
  labels,
  title,
  noPadding = false,
  onChangeSelected
}: FilterSelectProps) {
  const classes = useStyles();
  const textClasses = textStyles({});

  const handleChangeStatus = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChangeSelected(Number(event.target.value));
  };

  return (
    <TextField
      disabled={disabled}
      size="small"
      label={title}
      className={`${classes.customTextField} ${
        noPadding ? classes.noPadding : ''
      }`}
      select
      value={selected}
      onChange={handleChangeStatus}
    >
      {labels.map((label, i) => {
        if (label instanceof Object) {
          return (
            <MenuItem
              key={`${title}_${i}`}
              value={i}
              className={classes.chipContainer}
            >
              <StockSelectOption label={label.label} color={label.color} />
            </MenuItem>
          );
        }
        return (
          <MenuItem key={`${title}_${i}`} value={i}>
            <Typography
              variant="body1"
              color="text.primary"
              className={textClasses.ellipsis}
            >
              {typeof label === 'string' ? label : ''}
            </Typography>
          </MenuItem>
        );
      })}
    </TextField>
  );
}
